import '../scss/styles.scss'

import $ from "jquery"

import * as bootstrap from 'bootstrap'

import hiraethLogo from '../imgs/white_hiraeth_logo.png'

const logoDiv = document.getElementById("black-white-daffodil");

const logo = new Image();
logo.src = hiraethLogo;
logo.alt = "A black and white daffodil representing the hiraeth group's logo."

logoDiv.appendChild(logo);

document.getElementById("contact-form").addEventListener("submit", (e) => {
    e.preventDefault();
    $("#contact-form-submit").addClass("disabled");
    sendContactEmail(e.target);
});

$(".nav-link").on("click", () => {
    $("#navbarSupportedContent").collapse("hide");
});

const sendContactEmail = (form) => {
    // Grab the form
    console.log("Send contact email!")
    let xhr = new XMLHttpRequest();
    xhr.onload = parseContactResponse.bind(this, xhr);

    xhr.open("POST", "/send-contact-email", true);

    xhr.send(new FormData(form));
};

const parseContactResponse = (xhr) => {
    console.log(xhr.response);
    if (xhr.status == 400) {
        const responseJSON = JSON.parse(xhr.response);
        // Some validation error
        if ("validationError" in responseJSON) {
            createAlert(responseJSON.validationError.message, "alert-danger");
        } else {
            createAlert("Unknown error!", "alert-danger");
        }
    } else if (xhr.status == 200) {
        // Success
        handleSuccess();

    } else {
        // Otherwise some sort of error
        createAlert("Unknown error!", "alert-danger");
    }
};

const handleSuccess = () => {
    createAlert("Message sent! We'll be in contact soon!", "alert-success")
    $("#contact-form").slideUp('slow');
}

const clearAlert = () => {
    const contactFormAlert = document.getElementById("contact-form-alert");
    $("contact-form-submit").removeClass("disabled");

    // Clear any existing alert
    contactFormAlert.innerHTML = '';
}

const createAlert = (message, style) => {
    clearAlert();
    const contactFormAlert = document.getElementById("contact-form-alert");

    // Create the new alert
    const alertText = document.createTextNode(message);
    const alert = document.createElement("div");
    alert.classList.add("alert", style);
    alert.setAttribute("role", "alert");
    alert.appendChild(alertText);
    contactFormAlert.appendChild(alert);
}
